import * as React from "react"
import Experiment, { ExperimentProps } from "./Experiment"
import GummyBunniesApp from "./apps/GummyBunniesApp"
import TunnelApp from "./apps/TunnelApp"

export default class Tunnel extends Experiment {
  constructor(props: ExperimentProps) {
    super(props)
    this.class = TunnelApp
  }
}
