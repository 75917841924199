import { IExperimentApp } from "../Experiment"
import { AbstractCanvasApp } from "./AbstractCanvasApp"
//import tunnelImage from "../../../images/tunnel.jpg"
import bunnyImage from "../../../images/bunnyMap.png"
import bunnyBumpMapImage from "../../../images/bunnyBumpMap.png"

export default class TunnelApp extends AbstractCanvasApp implements IExperimentApp {
  stopped: boolean
  renderer: THREE.WebGLRenderer
  camera: THREE.PerspectiveCamera
  mouseX = 0
  mouseY = 0
  scene: THREE.Scene
  tunnelMesh: THREE.Mesh
  tunnelTexture: THREE.Texture
  time: THREE.Clock
  start() {
    this.stopped=false
    function deg2rad(_degrees: number) {
      return (_degrees * Math.PI / 180)
    }
    this.scene = new THREE.Scene()
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth/window.innerHeight, 0.1, 1000)

    this.renderer = new THREE.WebGLRenderer({ antialias: true, canvas: this.canvas })
    this.renderer.setClearColor( 0x000000, 0 ) // background

    this.camera.position.z = -110
    this.camera.lookAt(this.scene.position)
    this.scene.fog = new THREE.Fog(0x000000, 100, 700)

    // Mesh
    const group = new THREE.Group()
    this.scene.add(group)

    // Lights
    const light = new THREE.AmbientLight( 0x404040 ) // soft white light
    this.scene.add( light )

    const directionalLight = new THREE.DirectionalLight( 0xffffff, 1 )
    directionalLight.position.set( 0, 128, 128 )
    directionalLight.intensity=2
    this.scene.add( directionalLight )

    this.tunnelTexture = THREE.ImageUtils.loadTexture(bunnyImage) //bunnyImage tunnelImage
    this.tunnelTexture.wrapT = this.tunnelTexture.wrapS = THREE.RepeatWrapping
    this.tunnelTexture.repeat.set( 1, 24 )

    const tunnelBumpTexture = THREE.ImageUtils.loadTexture(bunnyBumpMapImage) //bunnyImage tunnelImage
    tunnelBumpTexture.wrapT = this.tunnelTexture.wrapS = THREE.RepeatWrapping

    const material = new THREE.MeshPhongMaterial({
      transparent: true,
      map: this.tunnelTexture,
      side: THREE.BackSide,
      bumpMap: tunnelBumpTexture,
      bumpScale: 100,
    })
    this.tunnelMesh = new THREE.Mesh(
      new THREE.CylinderGeometry( 50, 50, 1024, 16, 32, true ),
      material,
    )
    this.tunnelMesh.rotation.x = deg2rad(90)
    this.tunnelMesh.position.z = 128
    this.scene.add(this.tunnelMesh)
    this.time = new THREE.Clock()


    document.addEventListener( 'mousemove', this.onMouseMove, false )
    window.addEventListener('resize', this.onResize, false)

    this.update()
  }
  // Mouse and resize events
  onMouseMove = ( event: MouseEvent ) => {
    this.mouseX = event.clientX - window.innerWidth/2
    this.mouseY = event.clientY - window.innerHeight/2
  }
  onResize = () => {
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
    //renderer.setSize(window.innerWidth, window.innerHeight) //TODO
  }
  update = () => {
    this.camera.position.x = this.mouseX * 0.05
    this.camera.position.y = -this.mouseY * 0.05
    this.camera.lookAt(this.scene.position)
    this.tunnelMesh.rotation.y=this.time.getElapsedTime()
    this.tunnelTexture.offset.y = this.time.getElapsedTime() * 2
    this.renderer.render(this.scene, this.camera)
    if(!this.stopped) {
      requestAnimationFrame(this.update)
    }
  }
  stop() {
    this.stopped=true
    document.removeEventListener( 'mousemove', this.onMouseMove, false )
    window.removeEventListener('resize', this.onResize, false)
  }
}
