import * as React from "react"
import Layout from "../../components/layout"
import { threeDependency } from "../../components/experiments/Experiment"
import Tunnel from "../../components/experiments/Tunnel"
import SEO from "../../components/seo"

const TunnelPage = () => (
  <>
    <Tunnel dependencies={threeDependency}/>
    <Layout>
        <SEO title="Experiment: Three.js Bunny Tunnel" keywords={[`three.js`, `webgl`]} />
        <h2 style={{color:'white'}}>Tunnel</h2>
    </Layout>
  </>
)

export default TunnelPage
